import { qcHrefSegment } from 'createHistory';
import { registerHandler, unregisterHandler } from 'hooks';
import { pcUtils } from '../pcUtils';
import React from 'react';
import { useHistory } from 'react-router-dom';

// Disabled 6/1/2021
// const QC_WORKSPACE_BOUNDARIES = [
//   pcConstants.quotesWorkspaceRoot,
//   pcConstants.customerresearchWorkspaceRoot,
//   pcConstants.claimsWorkspaceRoot,
//   pcConstants.pcRootPath,
// ];

const getAnchorTagIds = () => {
	const ids: string[] = [];
	const quoteCenterMenu = pcUtils.leftNav.getQuoteCenterNavList();
	quoteCenterMenu?.querySelectorAll('a').forEach(x => ids.push(x.id));

	return ids;
};

export const useQuoteCenterRouterForPc = () => {
	const latestTagIds = getAnchorTagIds();
	const [anchorTagIds, setAnchorTagIds] = React.useState(latestTagIds);
	const history = useHistory();

	const callback = React.useCallback(
		(event: Event) => {
			const hrefRaw: string = (event.currentTarget as any).href?.toLowerCase();
			const href = hrefRaw.toLowerCase();
			const qcSegment = qcHrefSegment.toLowerCase();
			const routeBase = href?.indexOf(qcSegment);
			if (routeBase > -1) {
				event.preventDefault();
				history.push(`/${href.substr(routeBase + qcSegment.length + 1, href.length)}`);
			}
			// Disabled 6/1/2021 - there is still discussion around this topic leaving code in but disabled.
			//  const boundaries = isPcWorkspaces ? QC_WORKSPACE_BOUNDARIES : [pcConstants.pcRootPath];
			// for (const segment of boundaries) {
			//   const routeBase = href.indexOf(segment);
			//   if (routeBase > -1) {
			//     //If the external boundary is not crossed but an internal boundry (between workspaces) is crossed.
			//     if (href.indexOf(initialWorkspace) < 0) {
			//       // attemp to update breadcrumbs
			//       // updatePcBreadcrumbs(
			//       //   initialWorkspace.includes(pcConstants.claimsWorkspaceRoot)
			//       //     ? { Quotes: initialWorkspace }
			//       //     : { Claims: initialWorkspace }
			//       // );
			//     }
			//     event.preventDefault();
			//     history.push(`/${href.substr(routeBase + segment.length + 1, href.length)}`);
			//     break;
			//   }
			// }
		},
		[history]
	);

	React.useEffect(() => {
		setAnchorTagIds(latestTagIds);
		// eslint-disable-next-line
	}, [JSON.stringify(latestTagIds)]);

	React.useEffect(() => {
		anchorTagIds.forEach(id => {
			registerHandler(document.getElementById(id), 'click', callback);
		});
		return () =>
			anchorTagIds.forEach(id => unregisterHandler(document.getElementById(id), 'click', callback));
	}, [anchorTagIds, history, callback]);
};
