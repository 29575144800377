import { isMSXUrl } from 'features-apollo/msx/urlUtils';
import { Location, LocationDescriptorObject, Path } from 'history';

/**
 * When in MSX, reads path name from query parameter and transforms the Location object
 * @param inputLocation
 */
export const transformLocation = (inputLocation: Location): Location => {
	if (!isMSXUrl()) {
		return inputLocation;
	}

	const route = getRouteFromState() || getRouteFromQueryParam(inputLocation.search);

	return route ? { ...inputLocation, pathname: route } : inputLocation;
};

/**
 * When in MSX reads path from query string parameter
 * @param search
 */
export const getRouteFromQueryParam = (search: string) => {
	const params = new URLSearchParams(search.toLowerCase());
	const extraQsParam = params.get('extraqs'); //path=home/quotes/my
	const parseExtraQSParams = new URLSearchParams(extraQsParam || undefined);
	return parseExtraQSParams.get('path');
};

/**
 * Unified Client Interface, UCI in dynamics uses state for navigation (browser/back and forward).
 * Within QC we use route from state on browser back/forward events
 */
export const getRouteFromState = () => {
	return window.history.state?.QCRoute;
};

/**
 * When in MSX, the route from url is transformed into query parameter
 * @param to
 */
export const transformRouteToQueryString = (to: any, state?: any) => {
	const existingState = window.history.state;
	if (typeof to == 'object') {
		return {
			search: updateMSXSearch(to.pathname, to.search),
			state: { ...existingState, ...to.state, QCRoute: to.pathname },
		} as LocationDescriptorObject;
	} else if (typeof to == 'string') {
		const uri = to.split('?'); //route?search
		return {
			search: updateMSXSearch(uri[0], uri[1]),
			state: { ...existingState, ...state, QCRoute: uri[0] },
		} as LocationDescriptorObject;
	} else {
		return to;
	}
};

export const getPathname = (pathOrLocationDescriptor: Path | LocationDescriptorObject<any>) => {
	if (typeof pathOrLocationDescriptor == 'string') {
		const uri = pathOrLocationDescriptor.split('?'); //route?search
		return uri[0];
	} else {
		return pathOrLocationDescriptor.pathname;
	}
};

/**
 * Transform to object representation
 * @param to
 * @param state
 */
export const getHistoryObject = (to: any, state?: any) => {
	if (typeof to == 'object') {
		return to;
	} else if (typeof to == 'string') {
		const uri = to.split('?'); //route?search
		return {
			pathname: uri[0],
			search: uri[1],
			state,
		} as LocationDescriptorObject;
	} else {
		return to;
	}
};

/**
 * Add route to extraqs param and any other search to data query param
 * @param route
 * @param search
 */
const updateMSXSearch = (route?: string, search?: string) => {
	const searchParams = new URLSearchParams(window.location.search.toLowerCase());
	const dataParamValue = getData(search);
	if (dataParamValue) {
		searchParams.set('data', dataParamValue);
	} else {
		searchParams.has('data') && searchParams.delete('data');
	}
	route && searchParams.set('extraqs', `path=${route}`);
	return searchParams.toString();
};

/**
 * get data param from search
 * @param search
 */
const getData = (search?: string) => {
	if (!search) {
		return;
	}
	const searchParams = new URLSearchParams(search.toLowerCase());
	return searchParams.get('data');
};
