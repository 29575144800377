import { pcConstants } from '../constants';

const getPartnerCenterFlightPanel = () =>
	document.getElementById(pcConstants.panels.flightPanel.id);

const getPartnerCenterMePanel = () => document.getElementById(pcConstants.panels.mePanel.id);

const getPartnerCenterNotificationPanel = () =>
	document.getElementById(pcConstants.panels.notificationPanel.id);

const getPartnerCenterPerformancePanel = () =>
	document.getElementById(pcConstants.panels.performancePanel.id);

const getPartnerCenterSupportPanel = () =>
	document.getElementById(pcConstants.panels.supportPanel.id);

const addClasstoPartnerCenterSupportPanel = (className: string) =>
	getPartnerCenterSupportPanel()?.classList.add(className);

const removeClassFromPartnerCenterSupportPanel = (className: string) =>
	getPartnerCenterSupportPanel()?.classList.remove(className);

const closePartnerCenterSupportPanel = () =>
	removeClassFromPartnerCenterSupportPanel(pcConstants.panels.supportPanel.isOpenClass);

const getPartnerCenterSettingsPanel = () =>
	document.getElementById(pcConstants.panels.settingsPanel.id);

export const partnerCenterPanelUtils = {
	flightPanel: {
		getElement: getPartnerCenterFlightPanel,
	},
	mePanel: {
		getElement: getPartnerCenterMePanel,
	},
	notificationPanel: {
		getElement: getPartnerCenterNotificationPanel,
	},
	performancePanel: {
		getElement: getPartnerCenterPerformancePanel,
	},
	supportPanel: {
		addClass: addClasstoPartnerCenterSupportPanel,
		close: closePartnerCenterSupportPanel,
		getElement: getPartnerCenterSupportPanel,
		removeClass: removeClassFromPartnerCenterSupportPanel,
	},
	settingsPanel: {
		getElement: getPartnerCenterSettingsPanel,
	},
};
