export const routes = {
	root: '/',
	notFound: '/404',
	dashboard: {
		root: '/dashboard',
		claims: {
			root: '/dashboard/claims',
			redirect: '/home/claims/referrals/my',
		},
		quotes: {
			root: '/dashboard/quotes',
			redirect: '/home/quotes/my',
		},
	},
	home: {
		root: '/home',
		pageNotFound: '/home/pagenotfound',
		catalog: '/home/catalog',
		quotes: {
			root: '/home/quotes',
			my: '/home/quotes/my',
			customers: '/home/quotes/customers',
			myApprovals: '/home/quotes/my-approvals',
			activeClaims: '/home/quotes/active-claims',
			closedClaims: '/home/quotes/closed-claims',
			expiredClaims: '/home/quotes/expired-claims',
			catalogManagement: '/home/quotes/catalogManagement',
			mySearch: (searchQuery: string) => `${routes.home.quotes.my}?search=${searchQuery}`,
			createFromMSX: () => `${routes.home.quotes.my}?referrer=msx`,
			pricelist: '/home/quotes/pricelist',
		},
		referrals: {
			root: '/home/referrals',
			active: '/home/referrals/active',
			closed: '/home/referrals/closed',
			expired: '/home/referrals/expired',
		},
		customers: '/home/customers',
	},
	catalog: {
		redirect: '/catalog',
		root: '/catalog/:id',
		forId: (catalogId: string) => routes.catalog.root.replace(':id', catalogId),
	},

	sharedMeterDiscounts: {
		root: '/sharedmeterdiscounts/:id',
		forId: (quoteId: string) => routes.sharedMeterDiscounts.root.replace(':id', quoteId),
	},

	quote: {
		redirect: '/quote',
		root: '/quote/:id',
		approvals: '/quote/:id/approvals',
		customer: '/quote/:id/customer',
		partner: '/quote/:id/partner',
		indirectReseller: '/quote/:id/reseller',
		info: '/quote/:id/info',
		general: '/quote/:id/general',
		terms: '/quote/:id/terms',
		summary: '/quote/:id/summary',
		solution: '/quote/:id/solution',
		projection: '/quote/:id/projection',
		attach: '/quote/:id/attach',
		sales: '/quote/:id/sales',
		reviewAndPublish: '/quote/:id/reviewandpublish',
		confirmPublish: '/quote/:id/confirmpublish',
		shareQuote: '/quote/:id/sharequote',
		sharedMeterDiscounts: '/quote/:id/sharedmeterdiscounts',
		products: {
			root: '/quote/:id/products',
			cloud: '/quote/:id/products/:cloud',
		},
		forId: (quoteId: string) => routes.quote.root.replace(':id', quoteId),
		approvalsForId: (quoteId: string) => routes.quote.approvals.replace(':id', quoteId),
		customerForId: (quoteId: string) => routes.quote.customer.replace(':id', quoteId),
		partnerForId: (quoteId: string) => routes.quote.partner.replace(':id', quoteId),
		indirectResellerForId: (quoteId: string) =>
			routes.quote.indirectReseller.replace(':id', quoteId),
		salesForId: (quoteId: string) => routes.quote.sales.replace(':id', quoteId),
		reviewAndPublishForId: (quoteId: string) =>
			routes.quote.reviewAndPublish.replace(':id', quoteId),
		confirmPublishForId: (quoteId: string) => routes.quote.confirmPublish.replace(':id', quoteId),
		shareQuoteForId: (quoteId: string) => routes.quote.shareQuote.replace(':id', quoteId),
		infoForId: (quoteId: string) => routes.quote.info.replace(':id', quoteId),
		productsForId: (quoteId: string) => routes.quote.products.root.replace(':id', quoteId),
		generalForId: (quoteId: string) => routes.quote.general.replace(':id', quoteId),
		termsForId: (quoteId: string) => routes.quote.terms.replace(':id', quoteId),
		sharedMeterDiscountsForId: (quoteId: string) =>
			routes.quote.sharedMeterDiscounts.replace(':id', quoteId),
		summaryForId: (quoteId: string) => routes.quote.summary.replace(':id', quoteId),
		solutionForId: (quoteId: string) => routes.quote.solution.replace(':id', quoteId),
		projectionForId: (quoteId: string) => routes.quote.projection.replace(':id', quoteId),
		attachForId: (quoteId: string) => routes.quote.attach.replace(':id', quoteId),
		productsForIdAndCloud: (quoteId: string, cloud: string) =>
			routes.quote.products.cloud.replace(':id', quoteId).replace(':cloud', cloud),
	},

	customer: {
		redirect: '/customer',
		root: '/customer/:id',
		overviewRoot: '/customer/:id/overview',
		overview: '/customer/:id/overview/:pivot?',
		agreementsRoot: '/customer/:id/agreements',
		agreements: '/customer/:id/agreements/:filter?/:agreementId?/:detailsTab?',
		billingAccountsRoot: '/customer/:id/billingAccounts',
		billingAccounts: '/customer/:id/billingAccounts/:pivot?/:dropdown?/:selectedId?',
		concessionsRoot: '/customer/:id/concessions',
		concessions: '/customer/:id/concessions/:pivot?/:selectedId?/',
		historyRoot: '/customer/:id/history',
		history: '/customer/:id/history/:pivot?/:selectedId?/',
		enterpriseSupportRoot: '/customer/:id/support',
		enterpriseSupport: '/customer/:id/support/:pivot?/',
		subscriptionsRoot: '/customer/:id/subscriptions',
		subscriptions: '/customer/:id/subscriptions/:filter?/:tenantId?/:productId?',
		about: '/customer/:id/about',
		azure: 'http://bing.com',
		forId: (customerId: string) => routes.customer.root.replace(':id', customerId),
		agreementsForId: (customerId: string) =>
			`${routes.customer.agreementsRoot.replace(':id', customerId)}/active`,
		agreementById: (customerId: string, agreementId: string) =>
			`${routes.customer.agreementsRoot.replace(':id', customerId)}/active/${agreementId}/info`,
		amendmentsForId: (customerId: string, agreementId: string) =>
			`${routes.customer.agreementsRoot.replace(
				':id',
				customerId
			)}/active/mca.${agreementId}/modernAmendments`,
		billingAccountsById: (customerId: string) =>
			`${routes.customer.billingAccountsRoot.replace(':id', customerId)}`,
		concessionsForId: (customerId: string) =>
			`${routes.customer.concessionsRoot.replace(':id', customerId)}`,
		enterpriseSupportCWAForId: (customerId: string) =>
			`${routes.customer.enterpriseSupportRoot.replace(':id', customerId)}`,
		historyForId: (customerId: string) =>
			`${routes.customer.historyRoot.replace(':id', customerId)}`,
		subscriptionsForId: (customerId: string) =>
			routes.customer.subscriptionsRoot.replace(':id', customerId),
		aboutForId: (customerId: string) => routes.customer.about.replace(':id', customerId),
		overviewById: (customerId: string) => routes.customer.overviewRoot.replace(':id', customerId),
	},
	proposal: {
		root: '/proposal/:id',
	},

	external: {
		root: '/external',
	},

	content: {
		articlePopout: '/content/article-popout',
	},
};
