import i18next from 'i18next';
import { matchPath } from 'react-router-dom';
import { routes } from 'routes';

// Note: The order in which routes are matched matters
const QUOTES_SUB_ROUTES = (t: i18next.TFunction) => [
	{
		text: t('common::Needs my approval'),
		root: routes.home.quotes.myApprovals,
	},
	{
		text: t('common::My proposals'),
		root: routes.home.quotes.my,
	},
	{
		text: t('common::Contract catalog management'),
		root: routes.home.quotes.catalogManagement,
	},
];

const QUOTES_SUB_ROUTES_MSX = (t: i18next.TFunction) => [
	{
		text: t('common::Needs my approval'),
		root: routes.home.quotes.myApprovals,
	},
	{
		text: t('common::My proposals'),
		root: routes.home.quotes.my,
	},
	{
		text: t('common::Active'),
		root: routes.home.quotes.activeClaims,
	},
	{
		text: t('common::Expired'),
		root: routes.home.quotes.expiredClaims,
	},
	{
		text: t('common::Closed'),
		root: routes.home.quotes.closedClaims,
	},
	{
		text: t('common::Contract catalog management'),
		root: routes.home.quotes.catalogManagement,
	},
];

const CLAIMS_SUB_ROUTES = (t: i18next.TFunction) => [
	{
		text: t('common::Active'),
		root: routes.home.referrals.active,
	},
	{
		text: t('common::Expired'),
		root: routes.home.referrals.expired,
	},
	{
		text: t('common::Closed'),
		root: routes.home.referrals.closed,
	},
];

const HOME_SUB_ROUTES = (t: i18next.TFunction) => [
	{
		text: t('common::Claims'),
		root: routes.home.referrals.root,
		routes: CLAIMS_SUB_ROUTES(t),
	},
	{
		text: t('common::Proposal'),
		root: routes.home.quotes.root,
		routes: QUOTES_SUB_ROUTES(t),
	},
	{
		text: t('home::Commerce Insights'),
		root: routes.home.customers,
		routes: undefined,
	},
];

export const BASE_ROUTES = (t: i18next.TFunction) => [
	{
		//If home is not last it will match before claims or quotes must be last
		text: t('common::Home'),
		routes: HOME_SUB_ROUTES(t),
		root: routes.home.root,
	},
	{
		text: t('common::Proposal'),
		routes: routes.quote,
		root: routes.quote.root,
	},
	{
		text: t('common::Customer'),
		routes: routes.customer,
		root: routes.customer.root,
	},
];

export const BASE_ROUTES_MSX = (t: i18next.TFunction) => [
	{
		text: t('common::Proposals'),
		root: routes.home.quotes.root,
		routes: QUOTES_SUB_ROUTES_MSX(t),
	},
	{
		text: t('common::Customer search'),
		root: routes.home.customers,
	},
	{
		text: t('common::Proposal'),
		routes: routes.quote,
		root: routes.quote.root,
	},
	{
		text: t('common::Customer'),
		routes: routes.customer,
		root: routes.customer.root,
	},
];

export const getBaseRoutePath = (pathName: string, t: i18next.TFunction = () => {}) => {
	let match = null;
	for (let baseRoute of BASE_ROUTES_MSX(t)) {
		match = matchPath(pathName, { path: baseRoute.root });
		if (match) {
			return match.path;
		}
	}
	return match;
};

export const getBaseRoute = (pathName: string, t: i18next.TFunction = () => {}) => {
	let match = null;
	for (let baseRoute of BASE_ROUTES_MSX(t)) {
		match = matchPath(pathName, { path: baseRoute.root });
		if (match) {
			return baseRoute;
		}
	}
	return match;
};
