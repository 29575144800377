import React from 'react';

export const useElementClassObserver = (
	element: HTMLElement | Element | null,
	callback: (classList: string[] | null, mutation: MutationRecord) => void,
	isDisabled?: boolean
) => {
	React.useEffect(() => {
		if (isDisabled) {
			return;
		}

		const observer = new MutationObserver((mutations: MutationRecord[]) => {
			mutations.forEach((mutation: MutationRecord) => {
				if (mutation.attributeName === 'class') {
					const classArray: string[] = [];
					(mutation.target as any)?.classList.forEach((x: any) => classArray.push(x));
					callback(classArray, mutation);
				}
			});
		});

		if (element) {
			observer.observe(element, {
				attributes: true,
				attributeFilter: ['class'],
				childList: false,
				characterData: false,
			});
		}

		return () => {
			observer.disconnect();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDisabled, element, callback]);
};
