import React from 'react';

const sanitizeHtml = (html: string) =>
	import('sanitize-html')
		.then(module => module.default)
		.catch(() => {
			return (str: string) => '';
		})
		.then(sanitizer => sanitizer(html));

export function useSanitizeHtml(html: string) {
	const [sanitizedHtml, setSanitizedHtml] = React.useState<string>('');

	// TODO: There's a _strong_ possibility this isn't actually need. I don't think we're
	//       ever actually sanitizing HTML, it's just running over static text.

	React.useEffect(() => {
		if (!html || !html.includes('<')) {
			// if there's no `<`, that means there's no HTML tags, which means there's
			// nothing to really sanitize. Just set the original string.
			setSanitizedHtml(html);
			return;
		}

		const abortController = new AbortController();

		sanitizeHtml(html).then(sanitizedHtml => {
			if (!abortController.signal.aborted) {
				setSanitizedHtml(sanitizedHtml);
			}
		});

		return () => {
			abortController.abort();
		};
	}, [html]);

	return sanitizedHtml;
}
