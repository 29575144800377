export const msxConstants = {
	styles: {
		appHeight: 'calc(100vh - 49px)',
	},
	leftNav: {
		id: 'navbutton',
	},
	navbar: {
		icons: {
			supportIconId: 'msp.ApplicationRibbon.Support.Button',
			generalHelpId: 'helpLauncher',
		},
		navbarId: 'topBar',
	},
	msxRootPath: '',
	pageNameCommerceInsights: 'Commerce Insights',
	pageNameProposals: 'Proposals',
	pageNameQueryParam: 'name',
	wasMSXSidePanelAutoCollapsed: false, // used to maintain the toggle state of the left navigation
};
