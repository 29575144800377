import type { ElmPackageInfo } from 'features/app/elmConfig/elmPackages.schema';

class ElmConfig {
	private cachedElmConfig: ElmPackageInfo;

	public constructor() {
		this.cachedElmConfig = {} as ElmPackageInfo;
	}

	public getElmConfig(): ElmPackageInfo {
		return this.cachedElmConfig;
	}

	public setElmConfig(config: ElmPackageInfo): void {
		Object.assign(this.cachedElmConfig, config);
	}
}

const elmConfigManager = new ElmConfig();
export default elmConfigManager;
