import { pcConstants } from 'partnercenter';

export const helpPanelConstants = {
	buttons: {
		helpButton: 'navBar-help-button',
		pcHelpButton: pcConstants.navbar.icons.supportIconId,
	},
	classNames: {
		helpSection: 'HelpSection',
	},
};

export const ignoredHelpIds = [
	helpPanelConstants.buttons.helpButton,
	helpPanelConstants.buttons.pcHelpButton,
];
export const ignoredHelpClassNames = [helpPanelConstants.classNames.helpSection];

export const INT_MAX = 2147483647; // 2^31-1

// Source for this list is in GQL project api/catalog/types/commonTypes
// Unable to just export it from GQL due to one of the values including hyphens
export const PRODUCT_TYPES = {
	ANTICIPATED_CONTRACT_ESTIMATED_VALUE: 'Anticipated-Contract-Estimated-Value',
	APPLICABLE_LAW: 'ApplicableLaw',
	AZURE: 'Azure',
	AZURE_ACCESS_PASS: 'AzureAccessPass',
	AZURE_CREDITS: 'AzureCredits',
	AZURE_FAMILY_DISCOUNT: 'AzureFamilyDiscount',
	AZURE_MONETARY_COMMIT: 'AzureMonetaryCommit',
	AZURE_MONETARY_CREDIT: 'AzureMonetaryCredit',
	AZURE_SUPPORT: 'AzureSupport',
	COMMITMENT_TO_CONSUME: 'CommitmentToConsume',
	DISCOUNT_FULFILLMENT: 'DiscountFulfillment',
	DUAL_USE_RIGHTS: 'DualUseRights',
	ECIF: 'ECIF',
	FINANCING: 'Financing',
	PASS: 'PASS',
	SAP: 'SAP',
	SAVINGS_PLAN: 'SavingsPlan',
	SCP_COMMITMENT_TO_CONSUME: 'ScpCommitmentToConsume',
	SHARED_ONLINE_TERM: 'SharedOnline',
	PARTICIPATION: 'Participation',
	REQUEST_NEW_CUSTOM_TERM: 'RequestNewCustomTerm',
	VARIABLE_PRICING_AND_PAYMENT: 'VariablePricingAndPayment',
	INVOICE_OVERRIDE: 'InvoiceOverride',
	PROVISIONED_THROUGHPUT: 'ProvisionedThroughput',
	PUBLICITY: 'Publicity',
	DIVESTITURE_PERIOD: 'DivestiturePeriod',
};

export const SELLABLE_BY_RELATIONSHIP_TYPE = 'SellableBy';
export const EMPTY_ARRAY = [];
export const DELETE_SINGLE_PROPOSAL_BUTTON_ID = 'deleteSingleProposalButton';
export const DELETE_MULTIPLE_PROPOSAL_BUTTON_ID = 'deleteMultipleProposalButton';
export const MULTI_SELECT_TEACHING_BUBBLE_ANCHOR_ID = 'multiSelectTeachingBubbleAnchor';
// NON DFD ones are NOT counted to the discount limit
export const PROPOSAL_MAX_DFD_LINE_ITEMS = 140;

export const NCOE_TEST_MODE = 'ncoeTestMode';
