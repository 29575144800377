// Importing indexApp.tsx is done dynamically to allow for the appsettings.json file to be loaded first.
// If import need to be included at top level make sure those imports are not dependent on appsettings.json file.
// It also handles the logic for overriding appsettings based on hostname for MSX environments.

import configManager from 'features/app/config/configManager';
import * as LocalStorage from 'services/local-storage-service';
import { DefaultFlightMode, FlightsAppConfig, IAppConfig } from 'features/app/config/configType';
import { NCOE_TEST_MODE } from 'features/app/constants';

export const publicUrl = process.env.PUBLIC_URL || '';

export function isLocalUrl() {
	return (
		window.location.hostname === 'local.c3.mepla.microsoft.com' ||
		window.location.hostname === 'localhost' ||
		window.location.hostname === 'msxplatform.crm.dynamics.com'
	);
}

export async function initializeAppConfig() {
	try {
		const response = isLocalUrl()
			? await fetch(`${publicUrl}/settings/ms.local.appsettings.json`)
			: await fetch(`${publicUrl}/appsettings.json`);
		if (!response.ok) {
			throw new Error(
				`Failed to fetch proposal workspace appsettings: ${response.status} ${response.statusText}`
			);
		}
		let data = (await response.json()) as IAppConfig;
		data = processData(data);

		// If test mode is allowed and test headers are the default, set test mode to true
		const isTestModeEnabled = LocalStorage.get(NCOE_TEST_MODE);
		if (
			data.services.allowTestMode &&
			data.services.useTestHeader &&
			isTestModeEnabled === undefined
		) {
			LocalStorage.set(NCOE_TEST_MODE, true);
		} else if (data.services.allowTestMode && isTestModeEnabled !== undefined) {
			data.services.useTestHeader = Boolean(isTestModeEnabled);
		}

		configManager.setConfig(data);
	} catch (error) {
		console.error(`Error in proposal workspace - initializeAppConfig: ${error}`);
	}
}

export function processData(data: IAppConfig): IAppConfig {
	// override appsettings based on hostname
	if (data && data.services != null) {
		switch (window.location.hostname) {
			case data.hostnames?.msxLocal:
			case data.hostnames?.msxSupport: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.msxProd,
				} as FlightsAppConfig;
				break;
			}
			case data.hostnames?.msxDev: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.msxLatest,
				} as FlightsAppConfig;
				break;
			}
			case data.hostnames?.msxFedSalesDev: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.FedSalesDev,
				} as FlightsAppConfig;
				if (data.services.quotecenterBackend != null) {
					data.services.quotecenterBackend.environmentOverrides = {
						// we're intentionally targeting the fedsalesdev1 environment to INT Quotes API to facilitate the 100-day demo
						// initiative
						...data.services.quotecenterBackend.environmentOverrides,
						crmfgoeenvironment: 'fedsalesdev1',
					};
				}
				break;
			}
			case data.hostnames?.msxUsxDemo: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.UsxDemo,
				} as FlightsAppConfig;
				if (data.services.quotecenterBackend != null) {
					data.services.quotecenterBackend.environmentOverrides = {
						// we're intentionally targeting the usxdemo environment to INT Quotes API to facilitate the 100-day demo
						// initiative
						...data.services.quotecenterBackend.environmentOverrides,
						crmfgoeenvironment: 'usxdemo',
					};
				}
				break;
			}
			case data.hostnames?.msxPerf:
			case data.hostnames?.msxPreProd:
			case data.hostnames?.msxSQA:
			case data.hostnames?.msxUAT: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.msxStable,
				} as FlightsAppConfig;
				break;
			}
			case data.hostnames?.msxProd: {
				data.services.flights = {
					...data.services.flights,
					defaultFlightMode: DefaultFlightMode.msxProd,
				} as FlightsAppConfig;
				break;
			}
		}
	}
	return data;
}
