import { isLocalUrl, publicUrl } from 'appConfig';
import elmConfigManager from 'features/app/elmConfig/elmConfigManager';
import type { ElmPackageInfo } from 'features/app/elmConfig/elmPackages.schema';

export async function initializeElmConfig() {
	try {
		const response = isLocalUrl()
			? await fetch(`${publicUrl}/elmPackages/ms.test.elmpackages.json`)
			: await fetch(`${publicUrl}/elmpackages.json`);
		if (!response.ok) {
			throw new Error(
				`Failed to fetch proposal workspace ELM settings: ${response.status} ${response.statusText}`
			);
		}
		const elmData = (await response.json()) as ElmPackageInfo;

		elmConfigManager.setElmConfig(elmData);
	} catch (error) {
		console.error(`Error in proposal workspace - initializeElmConfig: ${error}`);
	}
}
