import { Action, Middleware } from 'redux';
import * as LocalStorage from 'services/local-storage-service';
import { RootState } from 'store/types';
import { getFlightIsEnabled } from 'features/app/selectors';
import { Flight } from 'services/flights/flightList';

const localStorageKey = 'redux-storage-middleware';

const mapStateToSave = (state: RootState) => {
	const isDemoMode = getFlightIsEnabled(state, Flight.demoMode);

	if (isDemoMode) {
		return {};
	}

	return {
		app: {
			isCachedFlightsEnabled: state.app.isCachedFlightsEnabled,
			flights: state.app.isCachedFlightsEnabled ? state.app.flights : undefined,
		},
	};
};

const createLocalStorageReduxMiddleware = () => {
	const localStorageReduxMiddleware: Middleware = store => next => (action: Action<string>) => {
		const result = next(action);
		const state = mapStateToSave(store.getState());
		LocalStorage.set(localStorageKey, state);
		return result;
	};

	return localStorageReduxMiddleware;
};

export const getLocalStorageState = (): Partial<RootState> => {
	const state = LocalStorage.get<RootState>(localStorageKey);

	if (state) {
		if (state.app && !state.app.isCachedFlightsEnabled) {
			delete (state as any).app.flights;
		}

		return state;
	}

	return {};
};

export const clearFlightsFromStorage = () => {
	const state = LocalStorage.get<RootState>(localStorageKey);

	if (state?.app?.flights) {
		delete (state as any).app.flights;
		LocalStorage.set<RootState>(localStorageKey, state as RootState);
	}
};

export const localStorageMiddleware = createLocalStorageReduxMiddleware();
