import { useEffect } from 'react';

export const useResizeAfterFirstRender = (
	shouldResizeAfterFirstRender?: boolean,
	resizeTimeout?: { firstResize: number; secondResize: number }
) => {
	useEffect(() => {
		if (shouldResizeAfterFirstRender) {
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
				setTimeout(() => {
					window.dispatchEvent(new Event('resize'));
				}, resizeTimeout?.firstResize ?? 600);
			}, resizeTimeout?.secondResize ?? 10);
		}
	}, [resizeTimeout, shouldResizeAfterFirstRender]);
};
