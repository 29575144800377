import { useSelector } from 'react-redux';
import { Stamp } from 'services/apollo/types/generated';
import { Flight } from 'services/flights/flightList';
import { pageLoadFlights } from 'services/flights/utils';
import { RootState } from 'store/types';

export const createLoadingSelector = (actions: string[]) => (state: RootState) =>
	actions.some(action => state.app.loading[action]);

export const createErrorMessageSelector = (actions: string[]) => (state: RootState) => {
	const error = actions.map(action => state.app.errors[action]).find(err => err);
	return error || '';
};

export interface Processing {
	loading: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	error: any;
}

export const createProcessingSelectors =
	(actions: string[]) =>
	(state: RootState): Processing => ({
		loading: createLoadingSelector(actions)(state),
		error: createErrorMessageSelector(actions)(state),
	});

export const getFlightIsEnabled = (state: RootState, id: Flight) => state.app.flights[id];

export const useIsFlightEnabled = (flight: Flight): boolean => {
	return useSelector((state: RootState) => getFlightIsEnabled(state, flight));
};

export const getAllFlights = (state: RootState) => state.app.flights;

export const wasFlightEnabledOnPageLoad = (id: Flight) => pageLoadFlights[id];

export const getStampIsEnabled = (state: RootState, id: Stamp | string): boolean =>
	state.app.stamps[id] || false;
export const getAllStamps = (state: RootState) => state.app.stamps;

// #region 'App configuration'

export const getApprovalHierarchyLevel = (state: RootState) => {
	if (getFlightIsEnabled(state, Flight.disableSagasAndEpics)) {
		// The value we read from the state was hardcoded to 'Country' in the original code
		return 'Country';
	}
	return state.app.config.approvalHierarchyLevel;
};

export const getSuppressedMessages = (state: RootState) => state.app.config.suppressedMessages;

export const getMinCreditLineDivisor = (state: RootState) => state.app.config.minCreditLineDivisor;

export const getWholeNumberRegex = (state: RootState) => state.app.config.wholeNumberRegex;

//#endregion
