import { IAppConfig } from './configType';

class AppConfig {
	private cachedAppConfig: IAppConfig;

	public constructor() {
		this.cachedAppConfig = {} as IAppConfig;
	}

	public getConfig(): IAppConfig {
		return this.cachedAppConfig;
	}

	public setConfig(config: IAppConfig): void {
		Object.assign(this.cachedAppConfig, config);
	}
}

const configManager = new AppConfig();
export default configManager;
