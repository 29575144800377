import {
	demoFlights,
	fedSalesDev1Flights,
	Flight,
	insiderFlights,
	latestFlights,
	productionFlights,
	qcInMSXLatestFlights,
	qcInMSXProdFlights,
	qcInMSXStableFlights,
	stableFlights,
	testFlights,
	usxDemoFlights,
} from './flightList';

export enum DefaultFlightMode {
	Latest = 'latest',
	Stable = 'stable',
	Test = 'test',
	Insider = 'insider',
	Prod = 'production',
	Demo = 'demo',
	msxLatest = 'msxlatest',
	msxStable = 'msxstable',
	msxProd = 'msxprod',
	UsxDemo = 'usxdemo',
	FedSalesDev = 'fedSalesDev',
}

export const defaultFlightList: Record<DefaultFlightMode, Record<Flight, boolean>> = {
	[DefaultFlightMode.Latest]: latestFlights,
	[DefaultFlightMode.Stable]: stableFlights,
	[DefaultFlightMode.Test]: testFlights,
	[DefaultFlightMode.Insider]: insiderFlights,
	[DefaultFlightMode.Prod]: productionFlights,
	[DefaultFlightMode.Demo]: demoFlights,
	[DefaultFlightMode.msxLatest]: qcInMSXLatestFlights,
	[DefaultFlightMode.msxStable]: qcInMSXStableFlights,
	[DefaultFlightMode.msxProd]: qcInMSXProdFlights,
	[DefaultFlightMode.UsxDemo]: usxDemoFlights,
	[DefaultFlightMode.FedSalesDev]: fedSalesDev1Flights,
};
