import React from 'react';
import { useElementClassObserver } from 'hooks';

export interface PartnerCenterPanelStatus {
	isOpen: boolean;
	width: number;
}

export const usePcPanelObserver = (
	panelElement: HTMLElement | null,
	panelOpenClass: string
): PartnerCenterPanelStatus => {
	const [panelState, setPanelState] = React.useState<PartnerCenterPanelStatus>({
		isOpen: false,
		width: 0,
	});

	useElementClassObserver(panelElement, (classList: string[] | null) => {
		const isPanelOpen = classList?.includes(panelOpenClass);

		setPanelState({
			isOpen: !!isPanelOpen,
			width: (!!isPanelOpen && panelElement?.clientWidth) || 0,
		});
	});

	return panelState;
};
