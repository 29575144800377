import { useEffect } from 'react';

import { msxConstants } from '../constants';

export const useToggleMSXLeftNav = (qcInMSX: boolean, reset?: boolean) => {
	useEffect(() => {
		if (!qcInMSX) {
			return;
		}
		// toggle left nav if open
		const msxLeftNavButton = document.querySelector<HTMLElement>(
			`[data-id='${msxConstants.leftNav.id}']`
		);

		if (!msxLeftNavButton) return;

		if (!reset && msxLeftNavButton.getAttribute('aria-expanded') === 'true') {
			msxLeftNavButton.click();
			msxConstants.wasMSXSidePanelAutoCollapsed = true;
		}

		if (
			reset &&
			msxConstants.wasMSXSidePanelAutoCollapsed &&
			msxLeftNavButton.getAttribute('aria-expanded') === 'false'
		) {
			msxLeftNavButton.click();
			msxConstants.wasMSXSidePanelAutoCollapsed = false;
		}
	}, [qcInMSX, reset]);
};
