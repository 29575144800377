import i18next from 'i18next';

import { ApolloError } from '@apollo/client';

import type { KeyNullableValues, Maybe } from 'services/apollo/types/generated';
import { currentQueryParams } from '@e2/router';
import { wasFlightEnabledOnPageLoad } from 'features/app/selectors';
import { Flight } from 'services/flights/flightList';

export const calloutWidth = 343;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeTypeName<T>(obj: T): T {
	if (!obj) {
		console.error('Sending undefined object to removeTypeName');
		console.trace();
	}
	const updated = { ...obj, __typename: undefined };
	delete updated.__typename;
	return updated;
}

export function filterAndCastMaybeArray<T>(arr: Maybe<T>[]) {
	return arr.filter(el => el !== null && el !== undefined) as T[];
}

export const getFilterValues = (key: string, filters?: KeyNullableValues[]): string[] => {
	if (!filters) {
		return [];
	}
	const searchedFilter = filters.find(filter => filter.key === key);
	const values = searchedFilter ? searchedFilter.values : [];
	return filterAndCastMaybeArray(values) || [];
};

type Primitives = string | number | undefined | boolean;

type PrimitiveArrayTypes =
	| (string | undefined)[]
	| (number | undefined)[]
	| (boolean | undefined)[];

export function removeArrayDuplicates(array: PrimitiveArrayTypes) {
	return Array.from(new Set<Primitives>(array));
}

export const isInvalidNumber = (s?: string | null) => {
	return (
		s == null ||
		isNaN(+s) ||
		!s.length ||
		s.includes(' ') ||
		s.includes('\t') ||
		s === '-0' ||
		s.toLowerCase().includes('e') //e is for 1e2 (10^2) the scientific representation
	);
};

export const joinRoute = (base: string, route: string) => {
	if (base[base.length - 1] === '/') {
		base = base.slice(0, -1);
	}
	return `${base}${route}`;
};

/*
 * Parses the data query param as query string params when in MSX
 * Example MSX query string: ?appid=dc6b28fe-62ee-e811-a83d-000d3a309c3d&pagetype=custom&name=msp_proposalcanvaspage_c71ff&data=setswitch%3Dqcinmsx%3A0
 */
export function parseQueryParams() {
	if (wasFlightEnabledOnPageLoad(Flight.e2Router)) {
		return currentQueryParams();
	}
	const params = new URLSearchParams(window.location.search.toLowerCase());
	if (params.has('data')) {
		const dataValue = params.get('data');
		return new URLSearchParams(dataValue ? decodeURIComponent(dataValue) : undefined);
	}
	return params;
}

/**
 * Removes the specified parameters from the search string.
 * When in MSX we modify the data param.
 * Example MSX query string: ?appid=dc6b28fe-62ee-e811-a83d-000d3a309c3d&pagetype=custom&name=msp_proposalcanvaspage_c71ff&data=setswitch%3Dqcinmsx%3A0
 */
export const getSearchWithQueryParamsRemoved = (
	searchParams: URLSearchParams,
	queryParamsToRemove: string[]
) => {
	queryParamsToRemove.forEach(queryParam => {
		if (searchParams.get(queryParam)) {
			searchParams.delete(queryParam);
		}
	});
	return searchParams.toString();
};

export const getAmountError = (
	amount: string,
	min: number,
	max: number,
	amountProperties: { fieldTouched: boolean; focused: boolean },
	labelType: 'amount' | 'quantity'
	// eslint-disable-next-line max-params
) => {
	const amountErrors = {
		numberOnly: i18next.t('error::Value must be a number'),
		empty:
			labelType === 'amount'
				? i18next.t('error::Please enter an amount')
				: i18next.t('error::Please enter a quantity'),
		lessThanMin: i18next.t('error::Value cannot be less than {{min}}', { min }),
		moreThanMax: i18next.t('error::Value cannot be more than {{max}}', { max }),
		noDecimals: i18next.t('error::Please enter a number with no decimals'),
	};

	if (!amount.trim() && amountProperties.fieldTouched && !amountProperties.focused) {
		return amountErrors.empty;
	}

	if (!amount.trim()) {
		return;
	}

	if (isInvalidNumber(amount)) {
		return amountErrors.numberOnly;
	} else if (amount.includes('.')) {
		return amountErrors.noDecimals;
	} else if (+amount < min) {
		return amountErrors.lessThanMin;
	} else if (+amount > max) {
		return amountErrors.moreThanMax;
	}
};
export const convertToOxfordCommaList = (words: string[]) => {
	const languageFormatter = new Intl.ListFormat(i18next.language, {
		style: 'long',
		type: 'conjunction',
	});
	return languageFormatter.format(words);
};

export const convertToCommaSeparatedListString = (words: string[]) => {
	const languageFormatter = new Intl.ListFormat(i18next.language, {
		style: 'narrow',
		type: 'conjunction',
	});
	return languageFormatter.format(words);
};

/**
 * Gets the first error's cv from an errors list
 * @param errors
 */
export function getApolloErrorCv(...errors: (ApolloError | undefined)[]) {
	const getApolloErrorCv = (error?: ApolloError): string | null => {
		const gqlErrors = error?.graphQLErrors;
		if (gqlErrors?.length) {
			const firstCv = gqlErrors.find(e => e && e.extensions?.msCv);
			return firstCv?.extensions?.msCv || null;
		}
		return null;
	};

	for (let error of errors) {
		const cv = getApolloErrorCv(error);
		if (cv) {
			return cv;
		}
	}

	return null;
}

// Constants for Terms logging
export const TERMS_LOG_PREFIX = 'Proposals.Terms';
export const USER_MERTICS_PREFIX = 'User.Metrics';
export const SH_LOG_PREFIX = 'Proposals';

export enum EVENT_NAME {
	LOAD = 'Load', // For GQL Get Queries
	CLICK = 'Click',
	OPEN = 'Open',
	CLOSE = 'Close',
	UPDATE = 'Update', // For GQL Mutations
	REFETCH = 'Refetch', // For GQL Refetch Queries
	FETCH = 'Fetch', // For REST API calls from the UI
	PARSE = 'Parse', // For parsing data from a JSON
}

export enum SCENARIO_NAME {
	LIST = 'QuoteList',
	CONFIGURATION = 'QuoteConfiguration',
	SUBMIT = 'QuoteSubmit',
	PUBLISH = 'QuotePublish',
}

export enum USER_ACTION {
	PUBLISH = 'Publish',
	UPDATE_PUBLISH = 'UpdatePublish',
	PAGE_LOAD = 'PageLoad',
	BUTTON_CLICK = 'ButtonClick',
}

export const getLogName = (
	component: string,
	query: string,
	eventName: EVENT_NAME,
	prefix?: string
) =>
	prefix
		? `${prefix}.${component}.${query}.${eventName}`
		: `${TERMS_LOG_PREFIX}.${component}.${query}.${eventName}`;

export const getTaskName = (path: string) => `${TERMS_LOG_PREFIX}.${path}`;

export const getUserMetricsName = (actionName: string) =>
	`${TERMS_LOG_PREFIX}.${USER_MERTICS_PREFIX}.${actionName}`;

export const isEtagMismatchError = (error: ApolloError | any) =>
	error &&
	error instanceof ApolloError &&
	error.graphQLErrors?.[0]?.extensions?.errorData?.Message ===
		'Cosmos DB update precondition failed, eTag mismatch';

export const getSHLogName = (name: SCENARIO_NAME, userAction: USER_ACTION, path: string) =>
	`${SH_LOG_PREFIX}.${name}.${userAction}.${path}`;
