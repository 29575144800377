import { pcConstants } from '../constants';

const getPartnerCenterSideNav = () => document.getElementById(pcConstants.leftNav.id);
const getQuoteCenterNavList = () => document.getElementById(pcConstants.leftNav.qcNavListId);

const minimizeLeftNav = () => {
	const oneDashNav = getPartnerCenterSideNav();

	if (oneDashNav && oneDashNav.classList.contains(pcConstants.leftNav.isOpenClass)) {
		const container = document.getElementById(pcConstants.leftNavToggleBtn.id);
		const button =
			container && container.getElementsByTagName(pcConstants.leftNavToggleBtn.tagToClick);
		button && button[0] && (button[0] as HTMLElement).click();
	}
};

export const partnerCenterLeftNavUtils = {
	getElement: getPartnerCenterSideNav,
	minimize: minimizeLeftNav,
	getQuoteCenterNavList,
};
