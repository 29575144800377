import AppInsightKey from 'features/app/config/appInsightsKeys.json';
import configManager from 'features/app/config/configManager';
import { AppEnvironment } from 'features/app/config/configType';
import { FlightsResponseV2 } from 'services/apollo/types/generated';
import { defaultFlightList } from 'services/flights/config';
import {
	Flight,
	FlightInformation,
	flights as flightInformations,
	getFlight,
	isFlight,
} from 'services/flights/flightList';
import * as LocalStorage from 'services/local-storage-service';
import loggerService from 'services/logger-service';
import { getLocalStorageState } from 'store/middleware/localStorageMiddleware';

const appConfigLocalStorageKey = 'localflights';
const flightsKey = 'setswitch';

export const getDefaultFlights = () => {
	return {
		...defaultFlightList[configManager.getConfig().services?.flights?.defaultFlightMode!],
	};
};

export const isFlightAvailableInEnvironment = (
	flight: Flight,
	env: AppEnvironment,
	flightInfos: Record<Flight, FlightInformation> = flightInformations
) => {
	const disableInEnvs = flightInfos[flight] && flightInfos[flight].disableInEnvs;
	return !(disableInEnvs && disableInEnvs.includes(env));
};

export const getFlightsFromUrl = (env: AppEnvironment): Partial<Record<Flight, boolean>> => {
	const result: Partial<Record<Flight, boolean>> = {};
	// This section is reimplemented since `parseQueryParams` depends on the e2Router flight so it's a circular dependency
	let searchParams = new URLSearchParams(window.location.search.toLowerCase());
	if (searchParams.has('data')) {
		const dataValue = searchParams.get('data');
		searchParams = new URLSearchParams(dataValue ? decodeURIComponent(dataValue) : undefined);
	} else if (searchParams.has('extraqs')) {
		try {
			const fakePath = searchParams.get('extraqs') ?? '';
			// The domain part is required to parse the path but doesn't matter and isn't used by us
			const fakeLocation = new URL(fakePath, 'https://example.com');
			searchParams = fakeLocation.searchParams;
		} catch {}
	}
	const flightsParam = searchParams.get(flightsKey);

	if (flightsParam) {
		const flightsList = flightsParam.split(',');

		flightsList.forEach(flight => {
			const [key, value] = flight.split(':');
			if (key && value && isFlight(key) && isFlightAvailableInEnvironment(key, env)) {
				const flight = getFlight(key);

				if (flight) {
					result[flight] = value === '1';
				}
			}
		});
	}

	return result;
};

export const applyForceEnabledFlights = (flights: Record<Flight, boolean>, env: AppEnvironment) => {
	if (!flights) {
		return;
	}
	Object.keys(flightInformations).forEach(flightKey => {
		const flightInformation = flightInformations[flightKey as Flight];
		if (flightInformation.forceEnableInEnvs?.includes(AppEnvironment.Prod)) {
			console.error(
				`You are using forceEnableInEnvs in prod for the flight key "${flightKey}" Do not use it with prod envs`
			);
		}
		if (flightInformation.forceEnableInEnvs?.includes(env)) {
			flights[flightKey as Flight] = true;
		}
	});
};

export const getCachedAppConfig = () => {
	try {
		return LocalStorage.get<FlightsResponseV2>(appConfigLocalStorageKey);
	} catch (error) {
		loggerService.error({ exception: error as Error });
	}
};

// validate cached flights in case the structure changes
export const validateCachedFlights = (flightResponse?: FlightsResponseV2) =>
	!!flightResponse?.flights;

export const applyAppConfigFlights = (
	flights: Record<Flight, boolean>,
	env: AppEnvironment,
	appConfigFlights: FlightsResponseV2 | undefined
) => {
	if (!appConfigFlights) {
		return;
	}

	appConfigFlights.flights
		.filter(flight => flight.enabled && isFlightAvailableInEnvironment(flight.name as Flight, env))
		.forEach(flight => (flights[flight.name as Flight] = true));

	appConfigFlights.flights
		.filter(flight => !flight.enabled)
		.forEach(flight => (flights[flight.name as Flight] = false));
};

const loadCachedFlights = (flights: Record<Flight, boolean>, env: AppEnvironment) => {
	try {
		let appConfigFlights = getCachedAppConfig();

		if (validateCachedFlights(appConfigFlights)) {
			applyAppConfigFlights(flights, env, appConfigFlights);
		}
	} catch (error) {
		loggerService.error({ exception: error as Error });
	}
};

/**
 * @param withUrlFlights Whether flights parsed from the URL should be included
 * in the returned object
 * @returns Default, AppConfig and (optionally) URL flights overridden in that order */
export const getInitialFlights = (withUrlFlights: boolean = true) => {
	const flights = getDefaultFlights();
	const env = configManager.getConfig()?.appEnvironment;
	loadCachedFlights(flights, env);

	return applyFlightOverrides(flights, env, withUrlFlights);
};

export const applyFlightOverrides = (
	flights: Record<Flight, boolean>,
	env: AppEnvironment,
	withUrlFlights: boolean = true
) => {
	applyForceEnabledFlights(flights, env);
	const isProd = configManager.getConfig().appInsightsKey === AppInsightKey.Prod;

	return {
		...flights,
		...(withUrlFlights && !isProd ? getFlightsFromUrl(env) : {}),
	};
};

export function setControlTowerFlights(flights: FlightsResponseV2) {
	LocalStorage.set<FlightsResponseV2>(appConfigLocalStorageKey, flights);
}

/**
 * Combines initial flights from defaults, URL, and localStorage cache
 *
 * Does not update during the page's lifetime to ensure the flight values are consistent the entire time.
 *
 * You don't normally want to use this. Special cases only.
 */
export const pageLoadFlights = {
	...getInitialFlights(),
	...(getLocalStorageState().app?.flights ?? {}),
};
