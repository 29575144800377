import { getMSXBaseUrl, getMSXUrl } from 'features-apollo/msx';
import { joinRoute } from 'features-apollo/utils/common.utils';

import { pcConstants } from './constants';
import { partnerCenterLeftNavUtils } from './utils/pcLeftNavUtils';
import { partnerCenterPanelUtils } from './utils/pcPanelUtils';
import { partnerCenterTopNavUtils } from './utils/pcTopNavUtils';
import { meplaHistory } from 'createHistory';
import { parsePath } from 'history';
import { wasFlightEnabledOnPageLoad } from 'features/app/selectors';
import { Flight } from 'services/flights/flightList';
import { isMSXUrl } from 'features-apollo/msx/urlUtils';

export const pcUtils = {
	leftNav: partnerCenterLeftNavUtils,
	topNav: partnerCenterTopNavUtils,
	panels: partnerCenterPanelUtils,
};

/**
 *
 * Returns the base url for QC
 * used for referencing content like pictures, js, css, within Partner Center
 */
export const getQcBaseUrl = () => process.env.PUBLIC_URL;

/**
 *
 * Returns the url for QC
 * used for referencing content like pictures, js, css, within Partner Center
 */
export const getQcUrl = (route: string) => {
	if (isMSXUrl()) {
		if (wasFlightEnabledOnPageLoad(Flight.e2Router)) {
			if (route === '') {
				return getMSXBaseUrl();
			}
			return `${getMSXBaseUrl()}${meplaHistory.createHref(parsePath(route))}`;
		}
		return getMSXUrl(route);
	}
	return `${isPartnerCenterUrl() ? getExternalUrlBase() : process.env.PUBLIC_URL}${route}`;
};

/*
 * Returns the Partner Center base url for QC
 * used for deep links to QC in Partner Center
 */
export const getExternalUrlBase = () =>
	`${joinRoute(window.location.origin, pcConstants.pcRootPath)}`;

export const isPartnerCenterUrl = () => window.location.host.includes('partner.microsoft');

export const isPcBreadcrumbsReady = () => !!(window as any)?.breadcrumbsReadyState;
export const updatePcBreadcrumbs = (crumbs: any) => (window as any)?.updateBreadcrumbs(crumbs);
