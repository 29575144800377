import React from 'react';

// https://github.com/spaciecat/react-hooks-series/blob/master/src/useHover.js
export function useHover<T extends HTMLElement | SVGElement>() {
	// Reference to the element we're listen for events from
	const ref = React.useRef<T>(null);

	// Hover state management
	const [hovered, setHovered] = React.useState(false);

	// Event handlers
	const enter = () => setHovered(true);
	const leave = () => setHovered(false);

	// Simple effect, just bind and unbind the event handlers
	React.useEffect(() => {
		const curr = ref.current;
		if (curr) {
			curr.addEventListener('mouseenter', enter);
			curr.addEventListener('mouseleave', leave);
			return () => {
				if (curr) {
					curr.removeEventListener('mouseenter', enter);
					curr.removeEventListener('mouseleave', leave);
				}
			};
		}
	}, [ref]);

	return { ref, hovered };
}
