import React from 'react';

export const registerHandler = (
	element: HTMLElement | Window | null,
	eventType: string,
	callback: EventListenerOrEventListenerObject
) => {
	if (element) {
		element.addEventListener(eventType, callback);
	}
};

export const unregisterHandler = (
	element: HTMLElement | Window | null,
	eventType: string,
	callback: EventListenerOrEventListenerObject
) => {
	if (element) {
		element.removeEventListener(eventType, callback);
	}
};

export const useEventListener = (
	element: HTMLElement | Window | null,
	eventType: string,
	callback: EventListenerOrEventListenerObject
) => {
	const [latestElement, setLatestElement] = React.useState(element);

	React.useEffect(() => {
		setLatestElement(element);
	}, [element]);

	React.useEffect(() => {
		registerHandler(latestElement, eventType, callback);

		return () => {
			unregisterHandler(latestElement, eventType, callback);
		};
	}, [callback, latestElement, eventType]);
};

export const useEventListenerById = (
	id: string,
	eventType: string,
	callback: EventListenerOrEventListenerObject
) => {
	useEventListener(document.getElementById(id), eventType, callback);
};

export const useEventListenerByDataId = (
	id: string,
	eventType: string,
	callback: EventListenerOrEventListenerObject
) => {
	useEventListener(document.querySelector<HTMLElement>(`[data-id='${id}']`), eventType, callback);
};
