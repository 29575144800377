// Importing indexApp.tsx is done dynamically to allow for the appsettings.json file to be loaded first.
// If import need to be included at top level make sure those imports are not dependent on appsettings.json file.
// It also handles the logic for overriding appsettings based on hostname for MSX environments.

import { initializeAppConfig } from 'appConfig';
import { initializeElmConfig } from 'elmConfig';

Promise.all([initializeAppConfig(), initializeElmConfig()]).then(async () => {
	await import(/* webpackChunkName: "indexApp" */ 'indexApp');
});
