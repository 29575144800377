import { pcConstants } from '../constants';
import { pcUtils } from '../pcUtils';
import { useElementClassObserver } from 'hooks';

export const usePcLeftNavObserver = (onLeftNavToggle: (isOpen: boolean, width: number) => void) => {
	useElementClassObserver(pcUtils.leftNav.getElement(), (classList: string[] | null) => {
		const isOpen = !!classList?.includes(pcConstants.leftNav.isOpenClass);

		//use hardcoded widths since we can't read the actual width due to the slide in/out animation
		onLeftNavToggle(isOpen, isOpen ? 275 : 48);
	});
};
