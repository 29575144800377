import { isPcWorkspaces } from 'createHistory';
import { pcConstants } from '../constants';
import React from 'react';
import { matchPath, RouteProps, useLocation } from 'react-router-dom';
import { routes } from 'routes';

const matchProps = { exact: false, strict: false };
const HIDDEN_FOR: RouteProps[] = [
	{ ...matchProps, path: routes.quote.root },
	{ ...matchProps, path: routes.customer.root },
];

export const usePcBreadCrumbs = () => {
	const enabled = isPcWorkspaces;
	const pcBreadcrumbs = document.getElementById(pcConstants.pcBreadcrumbs.containerId);
	const [breadcrumbContainer, setBreadcrumbContainer] = React.useState(pcBreadcrumbs);

	const location = useLocation();

	React.useEffect(() => {
		if (enabled) {
			setBreadcrumbContainer(pcBreadcrumbs);
		}
	}, [enabled, pcBreadcrumbs]);

	React.useEffect(() => {
		if (enabled && breadcrumbContainer !== null) {
			let unhide = true;
			for (const route of HIDDEN_FOR) {
				if (!!matchPath(location?.pathname, route)) {
					breadcrumbContainer.style.display = 'none';
					document.body.style.paddingTop = '48px';
					unhide = false;
					break;
				}
			}
			if (unhide) {
				breadcrumbContainer.style.display = pcConstants.pcBreadcrumbs.displayValue;
				document.body.style.paddingTop = pcConstants.pcBreadcrumbs.bodyPaddingValue;
			}
		}
	}, [breadcrumbContainer, enabled, location]);

	return {
		breadcrumbsVisible:
			enabled && breadcrumbContainer !== null && breadcrumbContainer?.style?.display !== 'none',
	};
};
