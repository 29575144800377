export const pcConstants = {
	styles: {
		appHeight: 'calc(100vh - (69px + var(--messageBars, 0px)))',
		appHeightBreadCrumbs: 'calc(100vh - 94px)',
	},
	pcBreadcrumbs: {
		containerId: 'breadcrumbcomponent',
		elementId: 'partnercenter-breadcrumbs',
		displayValue: 'block',
		bodyPaddingValue: '94px',
		bodyPaddingHiddenCrumbs: '48px',
	},
	pcDashboard: `${window.origin}/dashboard/home`,
	pcRootPath: '/dashboard/quotecenter',
	quotesWorkspaceRoot: '/dashboard/quotes',
	claimsWorkspaceRoot: '/dashboard/claims',
	customerresearchWorkspaceRoot: '/dashboard/customerresearch',
	leftNav: {
		id: 'onedash-side-navigation',
		isOpenClass: 'is-open',
		qcNavListId: 'onedash-top-menus',
	},
	leftNavToggleBtn: {
		id: 'onedash-hamburger',
		tagToClick: 'button',
	},
	// properties available from shell via sessionStorage.getitem()
	sessionStorage: {
		theme: 'darkMode',
		workspaces: 'workspaceUser',
	},
	styleSheets: {
		onedash: 'onedashboard.min.css',
	},
	// Class added to body when darkmode is enabled
	themeClass: 'darkMode',
	themeClassQC: 'darkQC',
	bottomSignInId: 'signInPrompt',
	panels: {
		flightPanel: {
			id: 'flightTools',
			isOpenClass: 'active',
		},
		supportPanel: {
			id: 'onedash-support-menu',
			isOpenClass: 'is-open',
		},
		performancePanel: {
			id: 'pagePerformancePanel',
			isOpenClass: 'active',
		},
		notificationPanel: {
			id: 'notificationList',
			isOpenClass: 'is-open',
		},
		settingsPanel: {
			id: 'onedash-settings-menu',
			isOpenClass: 'is-open',
			toggleIds: {
				theme: 'onedash-darkmode-toggle',
			},
			classes: {
				active: 'active',
				toggleOn: 'toggleRight',
			},
		},
		mePanel: {
			id: 'onedash-me-control-menu',
			isOpenClass: 'is-open',
		},
	},
	navbar: {
		icons: {
			feedbackIconId: 'onedash-feedback-icon',
			flightIconId: 'flightIcon',
			meControlIconId: 'onedash-me-control',
			notificationIconId: 'notificationGleam',
			pagePerfIconId: 'pagePerformanceIcon',
			settingsIconId: 'settingsCog',
			supportIconId: 'supportIcon',
		},
		navbarId: 'onedash-header-wrapper',
		supportIconSelectedClass: 'is-selected',
	},
};
