import { pcConstants } from '../constants';
import { pcUtils } from '../pcUtils';
import React from 'react';

import { PartnerCenterPanelStatus, usePcPanelObserver } from './usePcPanelObserver';

interface PartnerCenterPanels {
	isOpen: boolean;
	openPanelWidth: number;
	panels: {
		flightPanel: PartnerCenterPanelStatus;
		performancePanel: PartnerCenterPanelStatus;
		notificationPanel: PartnerCenterPanelStatus;
		supportPanel: PartnerCenterPanelStatus;
		mePanel: PartnerCenterPanelStatus;
		settingsPanel: PartnerCenterPanelStatus;
	};
}

export const usePcPanelState = () => {
	const flightPanel = usePcPanelObserver(
		pcUtils.panels.flightPanel.getElement(),
		pcConstants.panels.flightPanel.isOpenClass
	);

	const performancePanel = usePcPanelObserver(
		pcUtils.panels.performancePanel.getElement(),
		pcConstants.panels.performancePanel.isOpenClass
	);

	const notificationPanel = usePcPanelObserver(
		pcUtils.panels.notificationPanel.getElement(),
		pcConstants.panels.notificationPanel.isOpenClass
	);

	const supportPanel = usePcPanelObserver(
		pcUtils.panels.supportPanel.getElement(),
		pcConstants.panels.supportPanel.isOpenClass
	);

	const settingsPanel = usePcPanelObserver(
		pcUtils.panels.settingsPanel.getElement(),
		pcConstants.panels.settingsPanel.isOpenClass
	);

	const mePanel = usePcPanelObserver(
		pcUtils.panels.mePanel.getElement(),
		pcConstants.panels.mePanel.isOpenClass
	);

	const getIsPanelOpen = () =>
		flightPanel.isOpen ||
		mePanel.isOpen ||
		notificationPanel.isOpen ||
		performancePanel.isOpen ||
		supportPanel.isOpen ||
		settingsPanel.isOpen;

	const getOpenPanelWidth = () => {
		if (flightPanel.isOpen && flightPanel.width) {
			return flightPanel.width;
		} else if (mePanel.isOpen && mePanel.width) {
			return mePanel.width;
		} else if (notificationPanel.isOpen && notificationPanel.width) {
			return notificationPanel.width;
		} else if (performancePanel.isOpen && performancePanel.width) {
			return performancePanel.width;
		} else if (supportPanel.isOpen && supportPanel.width) {
			return supportPanel.width;
		} else if (settingsPanel.isOpen && settingsPanel.width) {
			return settingsPanel.width;
		}
		return 0;
	};

	const getPanelState = () => ({
		isOpen: getIsPanelOpen(),
		openPanelWidth: getOpenPanelWidth(),
		panels: {
			flightPanel,
			mePanel,
			notificationPanel,
			performancePanel,
			supportPanel,
			settingsPanel,
		},
	});

	const [panelState, setPanelState] = React.useState<PartnerCenterPanels>(getPanelState());

	React.useEffect(() => {
		setPanelState(getPanelState());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [flightPanel, mePanel, notificationPanel, performancePanel, supportPanel, settingsPanel]);

	return panelState;
};
