import { PermissionsConfig } from 'features-apollo/user/types';
import { Permission } from 'services/apollo/types/generated';
import { AadEnvironment } from 'services/auth';
import { TestHeaderConfig } from 'services/types';

export type IAppConfig = {
	appEnvironment: AppEnvironment;
	appInsightsKey: string;
	aadTenantId: string;
	authority: string;
	aadAppRegistrationId: string;
	aad: AadEnvironment;
	services: ServicesAppConfig;
	permissions: PermissionsConfig;
	translation: TranslationAppConfig;
	links: LinksAppConfig;
	hostnames: HostNamesAppConfig;
	hostNameByPortalShell: { [key: string]: string };
	apolloRequestInspectorAvailable?: boolean;
	h5: H5Config | null;
};

export interface ServicesAppConfig extends TestHeaderConfig {
	allowTestMode: boolean;
	quotecenterBackend: QuoteCenterBackendAppConfig;
	approval: ServicesCommonAppConfig | undefined;
	addressValidation: ServicesCommonAppConfig | undefined;
	agreement: ServicesCommonAppConfig | undefined;
	bd360: ServicesCommonAppConfig | undefined;
	businessStore: businessStoreAppConfig | undefined;
	configurePrice: ServicesCommonAppConfig;
	crm: CRMAppConfig | undefined;
	cwa: CWAAppConfig | undefined;
	ldss: ServicesCommonAppConfig | undefined;
	notifications: ServicesCommonAppConfig | undefined;
	oneAsk: ServicesCommonAppConfig | undefined;
	proposal: ProposalAppConfig | undefined;
	proposalAttachment: ServicesCommonAppConfig | undefined;
	purchase: ServicesCommonAppConfig | undefined;
	serviceDeskAttachments: ServicesCommonAppConfig | undefined;
	signUp: ServicesCommonAppConfig | undefined;
	flights: FlightsAppConfig | undefined;
}

export interface ServicesCommonAppConfig {
	endpoint: string;
	endpoint1P?: string;
	scopes: string[];
	scopes1P?: string[];
}

export interface CRMAppConfig extends ServicesCommonAppConfig {
	onBehalfOf: boolean;
	externalLinkMSX: string;
}

export interface businessStoreAppConfig {
	endpoint: string;
	endpointForIndirectFGI: string;
}

export interface CWAAppConfig {
	endpoint: string;
}

export interface FlightsAppConfig extends ServicesCommonAppConfig {
	defaultFlightMode: DefaultFlightMode;
	displayFlightPanel: boolean;
}

export interface PermissionAppConfig {
	allOf: Permission[] | undefined;
	oneOf: Permission[];
}

export interface ProposalAppConfig extends ServicesCommonAppConfig {
	endpointV12: string;
	supportOfferUrl: string;
	claimType: ProposalClaimTypeAppConfig;
	productIds: ProposalProductIdsAppConfig;
	totalAzurePlansAvailable: number;
	maxDiscountableLineItems: number;
	maxPurchasableLineItems: number;
}

export interface ProposalClaimTypeAppConfig {
	pricingAudience: string;
	nationalCloud: string;
	agreementType: string;
}

export interface ProposalProductIdsAppConfig {
	azurePlan: string;
	discountFulfillmentDocument: string;
	azureFamilyDiscount: string;
}

export interface TranslationAppConfig {
	pseudoLoc: string;
}
export interface QuoteCenterBackendAppConfig extends ServicesCommonAppConfig {
	useEnvironmentOverrides: boolean;
	environmentOverrides: OverridableEnvironments;
}

export interface OverridableEnvironments {
	addressvalidationenvironment?: string;
	adminenvironment?: string;
	agreementsenvironment?: string;
	approvalenvironment?: string;
	bd360environment?: string;
	businesscontactenvironment?: string;
	catalogenvironment?: string;
	configurepriceenvironment?: string;
	ccfenvironment?: string;
	creditenvironment?: string;
	crmenvironment?: string;
	crmfgoeenvironment?: string;
	dealtotalityenvironment?: string;
	edgeenvironment?: string;
	edgesearchenvironment?: string;
	empowermentenvironment?: string;
	entitlementenvironment?: string;
	mcapiauthorizationenvironment?: string;
	oneaskenvironment?: string;
	organizationenvironment?: string;
	pricingscopeenvironment?: string;
	projectenvironment?: string;
	purchaseenvironment?: string;
	quoteenvironment?: string;
	recipientenvironment?: string;
	relationshipenvironment?: string;
	servicedeskenvironment?: string;
	userpreferencesenvironment?: string;
}

export interface HostNamesAppConfig {
	quotecenter: string;
	proposalsMicrosoftProd?: string;
	partnerProd?: string;
	partnerPPE?: string;
	partnerInt?: string;
	partnerTest?: string;
	msxLocal?: string;
	msxUAT?: string;
	msxProd?: string;
	msxPerf?: string;
	msxSQA?: string;
	msxDev?: string;
	msxSupport?: string;
	msxPreProd?: string;
	msxFedSalesDev?: string;
	msxUsxDemo?: string;
}
export interface LinksAppConfig {
	privacyUrl: string;
	termsOfUseUrl: string;
	fabricFontsUrl: string;
	fabricIconUrl: string;
	akaUrl: string;
	microsoftUrl: string;
	bingUrl: string;
	quoteCenterUrl: string;
	quoteCenterDemoUrl: string;
	quoteCenterLatestUrl: string;
	audioConferenceOverageRatesUrl: string;
	videoConferenceOverageRatesUrl: string;
	globalFinancialServicesUrl: string;
	sharedOnlineAccountsUrl: string;
	standardSupportUrl: string;
	learnB2CTenantsUrl: string;
	learnDomainsAdminTakeOverUrl: string;
	microsoftCustomerAgreementScopesUrl: string;
	learnFinancingTermsVideoUrl: string;
	learnHelpVideoUrl: string;
	learnBillingProfileUrl: string;
	learnECIFPolicyUrl: string;
	learnManageProposalPropertiesUrl: string;
	proposalDocsUrl: string;
	knowledgeBasePCHowToAccessUrl: string;
	knowledgeBaseMSXOverviewUrl: string;
	knowledgeBaseMSXFAQUrl: string;
	partnerCenterFeedbackUrl: string;
	fieldLedSupportUrl: string;
	getHelpUrl: string;
	ncoeSupportDocumentationUrl?: string;
	ncoePrivacyUrl?: string;
	acceptanceModesInformationUrl: string;
	howToGuide: string;
	rolesAndPermissions: string;
	billingAccounts: string;
	empowermentOrApprovals: string;
	viewAgreementAndTerms: string;
	seeMore: string;
	provideFeedback: string;
}

export enum AppEnvironment {
	Local = 'DEV',
	Latest = 'INT',
	Stable = 'PPE',
	Test = 'TEST',
	Prod = 'PROD',
}

export enum DefaultFlightMode {
	Latest = 'latest',
	Stable = 'stable',
	Test = 'test',
	Insider = 'insider',
	Prod = 'production',
	Demo = 'demo',
	msxLatest = 'msxlatest',
	msxStable = 'msxstable',
	msxProd = 'msxprod',
	UsxDemo = 'usxdemo',
	FedSalesDev = 'fedSalesDev',
}

export interface H5FragmentConfig {
	cdnUrl: string | null;
}

export interface H5Config {
	workspaces: H5FragmentConfig;
	terms: H5FragmentConfig;
	products: H5FragmentConfig;
	commerce: H5FragmentConfig;
}
