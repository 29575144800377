import configManager from 'features/app/config/configManager';

export const AppHostName = {
	MSX: {
		Local: configManager.getConfig().hostnames?.msxLocal,
		UAT: configManager.getConfig().hostnames?.msxUAT,
		Prod: configManager.getConfig().hostnames?.msxProd,
		Perf: configManager.getConfig().hostnames?.msxPerf,
		SQA: configManager.getConfig().hostnames?.msxSQA,
		Dev: configManager.getConfig().hostnames?.msxDev,
		Support: configManager.getConfig().hostnames?.msxSupport,
		PreProd: configManager.getConfig().hostnames?.msxPreProd,
		FedSalesDev: configManager.getConfig().hostnames?.msxFedSalesDev,
		UsxDemo: configManager.getConfig().hostnames?.msxUsxDemo,
	},
};

export const QC_LAYER_HOST_ID = 'qc-layer-host';
