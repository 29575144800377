import { type History, type Action, type Location, type UnregisterCallback } from 'history';
import { isMSXQuoteCenterWorkspace, msxConstants } from 'features-apollo/msx';
import { getBaseRoutePath } from 'features-apollo/routeHierarchy';
import { createBrowserHistory, LocationDescriptor, Path } from 'history';
import { getPathname, getRouteFromQueryParam, transformRouteToQueryString } from 'location.utils';
import { pcConstants } from 'partnercenter';

import { AppHostName, msxShellCommunicator } from './common';
import '@e2/router';
import { GlobalsManager } from '@e2/globals-manager';
import { wasFlightEnabledOnPageLoad } from 'features/app/selectors';
import { Flight } from 'services/flights/flightList';
import { isMSXUrl } from 'features-apollo/msx/urlUtils';

export let isPcWorkspaces = false;
export let qcHrefSegment = pcConstants.pcRootPath;
// Calling this sets the export values above if necessary
getBaseName();
const localeRegex = new RegExp('/[a-z]{2}-[a-z]{2}/dashboard');

/**
 * Maintains routing history within the app
 * key: route
 * value:{path: previous root route, url: previous route}
 */
export const routeHistory: Record<string, { path: string; url: string }> = {};

function getWorkspaceHrefSegment(): string | undefined {
	const locale = window.location.pathname.match(localeRegex);
	const pathName = window.location.pathname.toLowerCase();

	if (pathName.includes(pcConstants.quotesWorkspaceRoot)) {
		return locale ? `${locale.join()}/quotes` : pcConstants.quotesWorkspaceRoot;
	} else if (pathName.includes(pcConstants.claimsWorkspaceRoot)) {
		return locale ? `${locale.join()}/claims` : pcConstants.claimsWorkspaceRoot;
	} else if (pathName.includes(pcConstants.customerresearchWorkspaceRoot)) {
		return locale ? `${locale.join()}/customerresearch` : pcConstants.customerresearchWorkspaceRoot;
	}
}

export function getBaseName(): string {
	switch (window.location.hostname) {
		case 'partner.microsoft.com':
		case 'partner.microsoft-ppe.com':
		case 'partner.microsoft-int.com':
		case 'partner.microsoft-tst.com':
			const workspaceSegment = getWorkspaceHrefSegment();
			if (workspaceSegment) {
				isPcWorkspaces = true;
				qcHrefSegment = workspaceSegment;
				return qcHrefSegment;
			}
			return pcConstants.pcRootPath;
		case AppHostName.MSX.Dev:
		case AppHostName.MSX.Local:
		case AppHostName.MSX.Perf:
		case AppHostName.MSX.PreProd:
		case AppHostName.MSX.Prod:
		case AppHostName.MSX.SQA:
		case AppHostName.MSX.Support:
		case AppHostName.MSX.UAT:
		case AppHostName.MSX.FedSalesDev:
		case AppHostName.MSX.UsxDemo:
			return msxConstants.msxRootPath;
		case 'quotecenter-test.microsoft.com':
			// Our PR verification will deploy a copy of QC to https://quotecenter-test.microsoft.com/[branch-name]
			// That /[branch-name] needs to become the base path for the app to make sure routes are generated / checked
			// consistently and correctly. The `preDeployForPullRequest` script will write the branch name into the
			// .env file as `PR_BRANCH_NAME` If there's no variable set, the path will fall back to just '/' as for other standalone environments.
			const currentBranchName = process.env.PR_BRANCH_NAME ?? '';
			const basename = `/${currentBranchName}`;

			return basename;
		default:
			return '/';
	}
}

const browserHistory = createBrowserHistory({
	basename: getBaseName(),
});

export const getBackRoute = () => {
	const currentRoute = getRouteFromQueryParam(window.location.search);
	const currentBaseRoutePath = currentRoute && getBaseRoutePath(currentRoute);
	return currentBaseRoutePath && routeHistory[currentBaseRoutePath]?.url;
};

/**
 * Checks if history references are cyclic
 * Example: (Step 9 is where cyclic back should be avoided between quote editor and customer workspace)
 * 1. Select a quote which has a MSX ID with a tpid
 * 2. Back on Quote Editor, should navigate to quote list
 * 3. Click on Properties in quote editor
 * 4. Click on "view customer" in general tab
 * 5. Back on Customer workspace, should navigate to quote editor
 * 6. click on Microsoft customer agreement
 * 7. click on order history
 * 8. click view quote
 * 9. Back on quote editor, should navigate to quote list (Not: Customer workspace)
 * @param toRoute
 * @param previousRoute
 */
const isRouteCyclic = (toRoute: string, previousRoute: string) => {
	const routeHistoryValues = Object.values(routeHistory);
	const matchIndex = routeHistoryValues.findIndex(route => route.path === toRoute);
	if (matchIndex >= 0) {
		const matchKey = Object.keys(routeHistory)[matchIndex];
		return matchKey === previousRoute;
	}
	return false;
};

// Add to route history when switching between workspaces
function manageRouteHistory(pathOrLocationDescriptor: Path | LocationDescriptor<any>) {
	const currentRoute = getRouteFromQueryParam(window.location.search);
	const toRoute = getPathname(pathOrLocationDescriptor);
	if (!currentRoute || !toRoute) {
		return;
	}
	const toBaseRoutePath = getBaseRoutePath(toRoute);
	const currentBaseRoutePath = getBaseRoutePath(currentRoute);

	if (
		toBaseRoutePath &&
		currentBaseRoutePath &&
		// ignore sub routes
		toBaseRoutePath !== currentBaseRoutePath &&
		// ignore cyclic references
		!isRouteCyclic(toBaseRoutePath, currentBaseRoutePath)
	) {
		//key:base route, value: previous route
		//eg: key:'/customer/:id'  value:{path: '/quote/:id', url: '/quote/8f8f331a8562/customer'}
		routeHistory[toBaseRoutePath] = { path: currentBaseRoutePath, url: currentRoute };
	}
}

const overrideHistory = (history: History) => ({
	...history,
	push: (pathOrLocationDescriptor: Path | LocationDescriptor<any>, state?: any) => {
		if (isMSXQuoteCenterWorkspace()) {
			manageRouteHistory(pathOrLocationDescriptor);
			history.push(transformRouteToQueryString(pathOrLocationDescriptor, state));
		}
	},
	replace: (pathOrLocationDescriptor: Path | LocationDescriptor<any>, state?: any) => {
		if (isMSXQuoteCenterWorkspace()) {
			history.replace(transformRouteToQueryString(pathOrLocationDescriptor, state));
		}
	},
});

/**
 * (1) Override pushState and replaceState to ensure correct state object from react router is saved for MSX navigation
 * (2) Listen to popstate events for QC internal navigation
 */
function patchMSXHistory() {
	const _originalPushState = window.history.pushState;
	const _originalReplaceState = window.history.replaceState;

	window.history.pushState = (state, title, url) => {
		if ('key' in state && 'state' in state) {
			state = state.state;
		}

		_originalPushState.call(window.history, state, title, url);
	};

	window.history.replaceState = (state, title, url) => {
		if ('key' in state && 'state' in state) {
			state = state.state;
		}

		_originalReplaceState.call(window.history, state, title, url);
	};

	if (!window.history.state) {
		window.history.pushState({ qcHistoryLengthOnLoad: window.history.length }, '');
	} else {
		window.history.state.QCRoute = null;
		window.history.state.qcHistoryLengthOnLoad = window.history.length;
	}
}

if (isMSXUrl() && !wasFlightEnabledOnPageLoad(Flight.e2Router)) {
	patchMSXHistory();
}

export const meplaHistory = (() => {
	if (wasFlightEnabledOnPageLoad(Flight.e2Router)) {
		const globals = new GlobalsManager<{
			'foundations.router.history': Omit<History, 'listen' | 'block' | 'length'> & {
				// These are the History v5 types
				listen: (
					listener: (update: { action: Action; location: Location }) => void
				) => UnregisterCallback;
				block: (
					blocker: (transition: { action: Action; location: Location; retry: () => void }) => void
				) => UnregisterCallback;
			};
		}>();
		// @e2/router constructs the history instance upon being imported and saves it to the global via the @e2/globals-manager to be shared across multiple instances of the library
		const [historyInstance] = globals.useE2Global('foundations.router.history', {
			access: 'public',
		});
		const e2History = historyInstance()!;
		const patchedHistory: History = {
			...e2History,
			// We must re-implement these getters since you cannot spread them
			get action() {
				return e2History.action;
			},
			get location() {
				return e2History.location;
			},
			get length() {
				return window.history.length;
			},
			listen: listener => {
				return e2History.listen(obj => {
					listener(obj.location, obj.action);
				});
			},
			// WARNING: The History v4 and v5 versions of block behave notably differently where they cannot be translated perfectly into each other. - As of 9/5/2024 it isn't being used in this repo anyway. - This works in H5 code though.
			block: blocker => {
				throw new Error('Not supported');
			},
		};
		msxShellCommunicator.setIsE2Router(true);
		console.log('Using E2 History');
		return patchedHistory;
	}
	return isMSXUrl() ? overrideHistory(browserHistory) : browserHistory;
})();

let msxBackIndex =
	typeof window.history.state === 'object'
		? window.history.state?.msxBackIndex ?? window.history.length
		: window.history.length;
export function msxPreviousWorkspaceButtonOnClick(): void {
	meplaHistory.go(msxBackIndex - window.history.length - 1);
}

export function msxPreviousWorkspaceButtonEnabled(): boolean {
	return (
		msxBackIndex > window.history.state?.historyLengthOnInitialLoad &&
		msxBackIndex <= window.history.length
	);
}

msxShellCommunicator.subscribeToWorkspaceEvents('BreadcrumbStack', {
	// This doesn't get called on the first load
	onWorkspaceShown: () => {
		// Reset the back index if our workspace is loaded, then hidden, then loaded again
		msxBackIndex = window.history.length;
	},
});

type BreadcrumbItem = {
	historyIndex: number;
	category: number;
	location: Location;
};

const breadcrumbStack: BreadcrumbItem[] =
	typeof window.history.state === 'object' ? window.history.state?.breadcrumbStack ?? [] : [];
/**
 * There are 4 sections of the app, navigation within a section replace the latest breadcrumb.
 */
function getBreadcrumbItemCategory(path: string): number {
	// Quote list page
	if (path.startsWith('/home/quotes/')) {
		return 1;
	}
	// Quote details page
	if (path.startsWith('/quote/')) {
		return 2;
	}
	// Customer list page
	if (path.startsWith('/home/customers/')) {
		return 3;
	}
	// Customer details page
	if (path.startsWith('/customer/')) {
		return 4;
	}
	return 0;
}

export function breadcrumbGoBackOnClick(defaultLocation: string): void {
	// This is where you currently are
	breadcrumbStack.shift();
	// This is the previous page
	const breadcrumbItem = breadcrumbStack.shift();
	if (breadcrumbItem === undefined) {
		if (msxPreviousWorkspaceButtonEnabled()) {
			msxPreviousWorkspaceButtonOnClick();
		} else {
			meplaHistory.push(defaultLocation);
		}
	} else {
		meplaHistory.push(breadcrumbItem.location);
	}
}

if (wasFlightEnabledOnPageLoad(Flight.e2Router)) {
	meplaHistory.listen((location, action) => {
		updateBreadcrumbs(location.pathname);
	});
	updateBreadcrumbs(meplaHistory.location.pathname);
}

function updateBreadcrumbs(pathname: string): void {
	// Cleanup breadcrumbs if we navigate back beyond the breadcrumb
	while (breadcrumbStack.length > 0) {
		if (breadcrumbStack[0].historyIndex > window.history.length) {
			breadcrumbStack.shift();
		} else {
			break;
		}
	}
	const breadcrumbCategory = getBreadcrumbItemCategory(pathname);
	if (breadcrumbCategory > 0) {
		const topItem = breadcrumbStack[0];
		if (topItem?.category === breadcrumbCategory) {
			breadcrumbStack.shift();
		}
		breadcrumbStack.unshift({
			historyIndex: window.history.length,
			category: breadcrumbCategory,
			location: JSON.parse(JSON.stringify(meplaHistory.location)),
		});
		if (breadcrumbStack.length > 4) {
			breadcrumbStack.pop();
		}
	}
	// Store our back button variables in state for if the page gets reloaded so they can be restored
	window.history.replaceState(
		{
			...window.history.state,
			breadcrumbStack,
			msxBackIndex,
		},
		''
	);
}
