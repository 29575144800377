import { BASE_ROUTES } from 'features-apollo/routeHierarchy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router-dom';

export const useRouteDetails = (pathName?: string | null) => {
	const { t } = useTranslation();

	const [activeRouteDetails, setActiveRouteDetails] = React.useState({
		base: {
			route: '/',
			text: 'Home',
		},
		sub: {
			route: '/',
			text: '',
		},
		active: {
			route: '/',
			text: '',
		},
	});

	React.useEffect(() => {
		const details = {
			base: {
				route: '/',
				text: 'Home',
			},
			sub: {
				route: '/',
				text: '',
			},
			active: {
				route: '/',
				text: '',
			},
		};

		if (!pathName) {
			setActiveRouteDetails(details);
		} else {
			BASE_ROUTES(t).forEach(base => {
				if (matchPath(pathName, { path: base.root })) {
					details.base.text = base.text;
					details.base.route = base.root;

					if (base.routes) {
						for (const [, subvalue] of Object.entries(base.routes)) {
							const subRoute = subvalue as any;
							const subRoot = subRoute?.root;

							if (subRoot && matchPath(pathName, { path: subRoot })) {
								details.sub.text = subRoute?.text;
								details.sub.route = subRoot;

								if (subRoute?.routes) {
									for (const [, activevalue] of Object.entries(subRoute?.routes)) {
										const activeRoute = activevalue as any;
										const activeRoot = activeRoute?.root;

										if (activeRoot && matchPath(pathName, { path: activeRoot })) {
											details.active.text = activeRoute?.text;
											details.active.route = activeRoot;
											break;
										}
									}
								}
								break;
							}
						}
					}
					setActiveRouteDetails(details);
					return;
				}
			});
		}
	}, [pathName]);

	return activeRouteDetails;
};
