/**
 * The MSX Shell interface.
 */
interface MSXShell {
	onPageMount: Function;
	navigateToWorkspace: Function;
	isE2Router: boolean;
}

/** The workspace event subscriber interface. */
export interface WorkspaceEvents {
	onWorkspaceShown: (name: string, route: string) => void;
	onWorkspaceHidden: (name: string) => void;
}

/**
 * The MSXShellCommunicator class provides the functionality to communicate with MSX Shell.
 */
class MSXShellCommunicator {
	/** The shell namespace. */
	private namespace = 'msx-proposals-shell';

	/** The shell object. */
	private shell: MSXShell | null = null;

	/** The workspace event subscribers. */
	private workspaceEventSubscribers = new Map();

	/** Flag to track when workspace is being shown */
	private workspaceShown: boolean = false;

	/**
	 * Save shell reference when running in MSX context.
	 */
	constructor() {
		if (this.namespace in window) {
			// @ts-ignore
			this.shell = window[this.namespace];
			this.workspaceShown = true;
		}
	}

	/**
	 * Send onPageMount to MSX shell.
	 */
	public onPageMount() {
		this.shell && this.shell.onPageMount(this);
	}

	/**
	 * Send navigate command to MSX shell.
	 */
	public navigateToWorkspace(workspace: any) {
		this.shell && this.shell.navigateToWorkspace(workspace);
	}

	/**
	 * Tells the MSX Shell if we are using the E2 Router or not which controls if browser back/forward will re-execute the entire app.
	 */
	public setIsE2Router(usingE2Router: boolean) {
		if (this.shell) {
			this.shell.isE2Router = usingE2Router;
		}
	}

	/**
	 * Returns true, when either Proposal or Commerce insights workspace been shown, otherwise false.
	 */
	public isWorkspaceShown() {
		return this.workspaceShown;
	}

	/**
	 * Handler for workspace shown event. Notify the subscriber of workspace shown event.
	 * @param name - The workspace name
	 * @param route - The route
	 */
	public onWorkspaceShown(name: string, route: string) {
		this.workspaceShown = true;

		for (let eventHandler of this.workspaceEventSubscribers.values()) {
			eventHandler && eventHandler.onWorkspaceShown && eventHandler.onWorkspaceShown(name, route);
		}
	}

	/**
	 * Handler for workspace hidden event. Notify the subscriber of workspace hidden event.
	 * @param name - The workspace name
	 */
	public onWorkspaceHidden(name: string) {
		this.workspaceShown = false;

		for (let eventHandler of this.workspaceEventSubscribers.values()) {
			eventHandler && eventHandler.onWorkspaceHidden && eventHandler.onWorkspaceHidden(name);
		}
	}

	/**
	 * Subscribe to be notified for MSX workspace events.
	 * @param name - The subscriber name
	 * @param eventHandler - The event handler
	 */
	public subscribeToWorkspaceEvents(name: string, eventHandler: Partial<WorkspaceEvents>) {
		this.workspaceEventSubscribers.set(name, eventHandler);
	}

	/**
	 * Unsubscribe from receiving MSX workspace events.
	 * @param name - The subscriber name
	 */
	public unsubscribeFromWorkspaceEvents(name: string) {
		this.workspaceEventSubscribers.delete(name);
	}
}

/**
 * Export the singleton instance of the class.
 */
export const msxShellCommunicator = new MSXShellCommunicator();
