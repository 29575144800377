import { joinRoute } from 'features-apollo/utils';
import { AppEnvironment } from 'features/app/config/configType';
import { isMSXInTeams } from './urlUtils';

/*
 * Returns the MSX base url for QC
 * used for deep links to QC in MSX
 */
export const getMSXBaseUrl = () => `${joinRoute(window.location.origin, window.location.pathname)}`;

export const getMSXUrl = (route: string) => `${getMSXBaseUrl()}?${getSearch(route)}`;

export const getSearch = (route: string) => {
	const search = new URLSearchParams(window.location.search.toLowerCase());
	search.set('extraqs', `path=${route}`);
	return search.toString();
};

/**
 * Returns true when current workspace in MSX is Proposal or Commerce insights, otherwise false.
 * Check for the workspace name in url in addition to detecting we are within MSX because on browser back/forward navigation both react router and UCI try to manage routing.
 * The UCI popstate event does not have the name of the workspace and we do not want to handle that event
 */
export function isMSXQuoteCenterWorkspace() {
	// When in teams we do not have access to the same query params as in the web version
	if (isMSXInTeams()) {
		return true;
	}
	const searchParams = new URLSearchParams(window.location.search.toLowerCase());
	const workspace = searchParams.get('name');
	return workspace && (workspace.match(/proposal/gi) || workspace.match(/commerceinsights/gi));
}

// Documentation on building these links comes from: https://eng.ms/docs/cloud-ai-platform/commerce-ecosystems/commerce-partner-seller-experience/quote-center/l2o-quote-center/l2o-quote-center/onboard/deeplinkintoquotecenter
// As of 23 June, the redirects aren't pointing at MSX. They'll be switched over starting July 17.

export interface BuildMsxUrlArgs {
	/** The page you want to link to */
	link: 'quote' | 'customer' | 'referral';
	/**
	 * The name of the calling application. Used as a telemetry hint to track the number of requests from external
	 * applications. Defaults to 'PartnerCenter' if not provided.
	 * @default 'PartnerCenter'
	 */
	referrer?: string;
	/**
	 * Unique identifier of the object mentioned in the link
	 */
	id?: string;
}

function getCustomersInMsxVanityName(environment: AppEnvironment) {
	switch (environment) {
		case AppEnvironment.Local:
		case AppEnvironment.Latest:
			return 'mepla-tst/customer';
		case AppEnvironment.Prod:
			return 'mepla/customer';
		case AppEnvironment.Stable:
		case AppEnvironment.Test:
		default:
			return 'mepla-ppe/customer';
	}
}

function getProposalsInMsxVanityName(environment: AppEnvironment) {
	switch (environment) {
		case AppEnvironment.Local:
		case AppEnvironment.Latest:
			return 'mepla-tst/quotes';
		case AppEnvironment.Prod:
			return 'mepla/quotes';
		case AppEnvironment.Stable:
		case AppEnvironment.Test:
		default:
			return 'mepla-ppe/quotes';
	}
}

export function getProposalsInMsxUrl(environment: AppEnvironment, data: BuildMsxUrlArgs) {
	const params = new URLSearchParams([
		['link', data.link],
		['referrer', data.referrer ?? 'PartnerCenter'],
	]);
	if (data.id) {
		params.append('id', data.id);
	}

	const getVanityName =
		data.link === 'customer' ? getCustomersInMsxVanityName : getProposalsInMsxVanityName;

	const baseUrl = `https://aka.ms/${getVanityName(environment)}?data=${encodeURIComponent(
		params.toString()
	)}`;

	return baseUrl;
}
