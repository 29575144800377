import axios from 'axios';
import { pcConstants } from '../constants';
import React from 'react';

const togglePcTheme = (event: Event) => {
	event.stopPropagation();
	setTimeout(async () => {
		const toggleButton = document.getElementById(pcConstants.panels.settingsPanel.toggleIds.theme);
		const darkModeStatus = document.body.classList.contains(pcConstants.themeClassQC);

		if (toggleButton && !(toggleButton as HTMLInputElement)?.disabled) {
			const nonQuoteDivs = document.querySelectorAll('body > div:not(.qc-container)');
			(toggleButton as HTMLInputElement).disabled = true;
			if (!darkModeStatus) {
				document.body.classList.add(pcConstants.themeClassQC);
				if (nonQuoteDivs !== null) {
					nonQuoteDivs.forEach(
						div =>
							!div?.classList.contains(pcConstants.themeClass) &&
							div?.classList?.add(pcConstants.themeClass)
					);
				}
				toggleButton.classList.add(pcConstants.panels.settingsPanel.classes.active);
				toggleButton.classList.add(pcConstants.panels.settingsPanel.classes.toggleOn);
				sessionStorage.setItem('darkMode', 'on');
			} else {
				if (nonQuoteDivs !== null) {
					nonQuoteDivs.forEach(
						div =>
							div?.classList.contains(pcConstants.themeClass) &&
							div?.classList?.remove(pcConstants.themeClass)
					);
				}

				document.body.classList.remove(pcConstants.themeClassQC);
				toggleButton.classList.remove(pcConstants.panels.settingsPanel.classes.active);
				toggleButton.classList.remove(pcConstants.panels.settingsPanel.classes.toggleOn);
				sessionStorage.removeItem('darkMode');
			}
			// Update the users saved Partner Center Prefrences for darkmode
			await axios
				.put(
					//@ts-ignore
					`/${(window as any).locale()}/dashboard/engagementapi/identity/preferences`,
					{
						DarkMode: !darkModeStatus,
					}
				)
				.then(() => {
					const btn = document.getElementById(pcConstants.panels.settingsPanel.toggleIds.theme);
					if (btn) {
						(btn as HTMLInputElement).disabled = false;
					}
				});
		}
	}, 50);
};

export const usePcThemeToggle = () => {
	React.useEffect(() => {
		const toggleButton = document.getElementById(pcConstants.panels.settingsPanel.toggleIds.theme);
		if (toggleButton) {
			// Clear existing event handlers by creating a clone and replacing the element in the parent with the clone.
			const newToggleButton = toggleButton.cloneNode(true);
			const toggleTheme = (ev: Event) => {
				togglePcTheme(ev);
			};
			toggleButton.parentNode?.replaceChild(newToggleButton, toggleButton);

			newToggleButton.addEventListener('click', toggleTheme);
			return () => {
				newToggleButton.removeEventListener('click', toggleTheme);
			};
		}
	}, []);
};
