/**
 * The ConsoleLogger class provides the console logging functionality.
 */
class ConsoleLogger {
	/** The flag to keep track of logging enabled. */
	private loggingEnabled = false;

	/** This flag enables Msal console logging */
	private msalLoggingEnabled = false;

	constructor() {
		const value = window.localStorage.getItem('loggingEnabled');
		const msalValue = window.localStorage.getItem('msalLoggingEnabled');
		this.loggingEnabled = value !== null;
		this.msalLoggingEnabled = msalValue !== null;
	}

	/**
	 * Logs the message as normal to console.
	 * @param message - The message
	 */
	public log(message: string) {
		this.loggingEnabled && window.console.log(message);
	}

	/**
	 * Logs the message as warning to console.
	 * @param message
	 */
	public warn(message: string) {
		window.console.warn(message);
	}

	/** Logs msal messages to console */
	public msalLog(message: string) {
		this.msalLoggingEnabled && window.console.log(message);
	}

	/** Logs msal verbose messages to console debug(hidden if console does not have verbose enabled) */
	public msalVerbose(message: string) {
		this.msalLoggingEnabled && window.console.debug(message);
	}
}

/**
 * Export the singleton instance of the class.
 */
export const consoleLogger = new ConsoleLogger();
