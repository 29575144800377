import { pcConstants } from '../constants';

const getPartnerCenterNavbar = () => document.getElementById(pcConstants.navbar.navbarId);

const getPartnerCenterNavbarHeight = () => getPartnerCenterNavbar()?.clientHeight || 48;

const getPartnerCenterSupportIcon = () =>
	document.getElementById(pcConstants.navbar.icons.supportIconId);

const addHighlightStyleToNavbarSupportIcon = () =>
	getPartnerCenterSupportIcon()?.classList.add(pcConstants.navbar.supportIconSelectedClass);

const removeHighlightStyleFromNavbarSupportIcon = () =>
	getPartnerCenterSupportIcon()?.classList.remove(pcConstants.navbar.supportIconSelectedClass);

export const partnerCenterTopNavUtils = {
	getElement: getPartnerCenterNavbar,
	getHeight: getPartnerCenterNavbarHeight,
	icons: {
		support: {
			addHighlight: addHighlightStyleToNavbarSupportIcon,
			removeHighlight: removeHighlightStyleFromNavbarSupportIcon,
		},
	},
};
